@import "../../sass/mixins";

.jukebox {
  position: fixed;
  right: calc(5vw - 1rem);
  bottom: 5vw;
  overflow-y: hidden;
  button {
    height: 20vw;
    max-height: 8rem;
    width: 20vw;
    max-width: 8rem;
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid #bbb;
    margin: 0 1rem;
    transform: rotate(7.5deg) translateY(2vw);

    animation: boppingJukebox 3.5s infinite ease-in-out;
    animation-direction: alternate-reverse;
    mix-blend-mode: color-dodge;

    .mdi-icon {
      fill: #fff;
      @include md-icon-size(100%);
    }
    &:focus {
      outline: none;
    }
    &:hover {
      border-color: #888;
      .mdi-icon {
        fill: #bbb;
      }
    }
  }
}

@keyframes boppingJukebox {
  0% {
    transform: rotate(7.5deg) translateY(2vw);
  }
  100% {
    transform: rotate(8.5deg) translateY(4vw);
  }
}