.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #fff;
  main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 1.3rem;
      font-weight: 300;
      text-shadow: 0 0 .3rem #000;
      transform: rotate(2.5deg);
      margin: 0;
    }
  }
}

.App-header {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,0));
  padding: 1rem;
  font-size: 3rem;
  @media screen and (max-width: 400px) {
    font-size: 2rem;
  }
  span {
    &:nth-child(1){
      color: #BA122B;
    }
    &:nth-child(3){
      color: #00205B;
    }
    &:nth-child(5){
      color: #BA122B;
    }
  }
}