.my-cut {
  position: relative;
  font-size: 6rem;
  transform: rotate(-4deg);
  margin: 0 auto;
  .desc {
    display: block;
    font-size: 1.5rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 4.5rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 3.5rem;
  }
}