.cheese-slicers {
  text-shadow: 0 0 .3rem #000;
  .postfix {
    margin-left: .25rem;
    opacity: .75;
    font-weight: 300;
  }
  .prefix {
    margin-right: .25rem;
    opacity: .75;
    font-weight: 300;
  }
  ul {
    transform: rotate(2.5deg);
    list-style: none;
    li {
      font-weight: 600;
    }
  }
}