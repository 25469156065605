.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000 url(../assets/gfx/brudeferd.jpg);
  background-size: cover;
  opacity: .8;
  .kransekake {
    position: absolute;
    right: 0;
    transform: rotate(10deg);
    max-width: 20vw;
    user-select: none;

    animation: boppingCake 3s infinite ease-in-out;
    animation-direction: alternate-reverse;
    mix-blend-mode: color-burn;
  }
  .poles {
    position: absolute;
    left: -16.5vw;
    bottom: 15vh;
    transform: rotate(10deg);
    max-height: 35vw;
    user-select: none;

    animation: boppingPoles 3s infinite ease-in-out;
    animation-direction: alternate-reverse;
    mix-blend-mode: color-dodge;
  }
}


@keyframes boppingCake {
  0% {
    transform: rotate(10deg) translateY(2vw);
  }
  100% {
    transform: rotate(12deg) translateY(4vw);
  }
}

@keyframes boppingPoles {
  0% {
    transform: rotate(-90deg) translateY(2vw);
  }
  100% {
    transform: rotate(-85deg) translateY(4vw);
  }
}
