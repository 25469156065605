@mixin md-icon-size($size: 1.5rem) {
  font-size: $size;
  height: $size;
  width: $size;
  vertical-align: #{$size / -4};
}

@mixin addShadow($color: #000000){
  box-shadow: 0 2px 4px -1px rgba($color, .15), 0 6px 8px 0 rgba($color, .05), 0 1px 10px 0 rgba($color, .1);
}

@mixin addShadow2($color: #000000){
  box-shadow: rgb(226, 207, 213) 6px 6px 12px, rgb(247, 242, 244) -12px -12px 24px -2px;
}